<template>
  <section>
    <article class="container">
      <main class="card table-list">
        <h3>
          {{ $t("transaction_information") }}
        </h3>
        <!-- 交易记录详情 -->
        <template  v-if="tx">
          <ul class="layout-about-item-label">
            <li v-if="tx.hash">
              <span>{{ $t("EVMHash") }}</span>
              <p>
                <i class="text-blue">{{ tx.hash }}</i>
                <img
                  class="copy-btn"
                  @click="copyAction(tx.hash)"
                  src="@/static/img/icon/copy_grey.png"
                  width="16"
                />
              </p>
            </li>
            <li>
              <span>{{ $t("result") }}</span>
              <p class="text-green" v-if="tx.status">Success</p>
              <p class="text-orange" v-else>Fail ({{ tx.error_message }})</p>
            </li>
            <li v-if="tx.block && tx.block.number">
              <span>{{ $t("block") }}</span>
              <router-link
                class="text-blue"
                :to="'/block/' + tx.block.number"
                >{{ tx.block.number }}</router-link
              >
            </li>
            <li v-if="tx.block && tx.block.timestamp">
              <span>{{ $t("time") }}</span>
              <p>
                {{ webUtil.timestampToDate(tx.block.timestamp) }}
              </p>
            </li>
            <li v-if="tx.from">
              <span>{{ $t("sender") }}</span>
              <struct-address :address="tx.from" isEVM="1"/>
            </li>
            <li v-if="tx.to">
              <span>{{ $t("destination") }}</span>
              <struct-address :address="tx.to" isEVM="1"/>
            </li>
            <li v-if="tx.value">
              <span>{{ $t("value") }}</span>
              <p>
                {{ webUtil.formatNumber(tx.value, tokenDecimals) }}
                {{ tokenSymbol }}
              </p>
            </li>
            <li v-if="fee > 0">
              <span>{{ $t("fee") }}</span>
              <p>
                {{ fee }}
                {{ tokenSymbol }}
              </p>
            </li>
            <li v-if="tx.gas_price > 0">
              <span>{{ $t("GasPrice") }}</span>
              <p>
                {{ webUtil.formatNumber(tx.gas_price, tokenDecimals) }}
                {{ tokenSymbol }}
              </p>
            </li>
            <li v-if="tx.gas > 0">
              <span>{{ $t("GasLimit") }}</span>
              <p>
                {{ webUtil.addCommas(tx.gas, 0) }}
              </p>
            </li>
            <li v-if="tx.gas_used > 0">
              <span>{{ $t("GasTransaction") }}</span>
              <p>
                {{ webUtil.addCommas(tx.gas_used, 0) }}
                ({{ gas_percent }}%)
              </p>
            </li>
          </ul>
          <ul class="layout-about-item-label second">
            <li v-if="tx.nonce">
              <span>{{ $t("nonce") }}</span>
              <p>{{ tx.nonce }}</p>
            </li>
            <li v-if="tx.input">
              <span>{{ $t("InputData") }}</span>
              <p class="struct-table pd-12">{{ tx.input }}</p>
            </li>
          </ul>
        </template>
        <div class="null" v-else>{{ $t("noInformation") }}</div>
      </main>
    </article>
    <loading :loading="txLoading"></loading>
  </section>
</template>

<script>
import loading from "base/loading";
// import StructTable from "./struct-table.vue";
import StructAddress from "base/struct-address.vue";
export default {
  props: ["txID"],
  data() {
    return {
      tx: null,
      txLoading: true,
    };
  },
  computed: {
    fee() {
      if (this.tx && this.tx.gas_price && this.tx.gas_used) {
        return this.webUtil.BN(this.tx.gas_price)
          .times(this.tx.gas_used)
          .div(Math.pow(10, this.tokenDecimals))
          .toFixed();
      }
      return 0;
    },
    gas_percent() {
      if (this.tx && this.tx.gas && this.tx.gas_used) {
        return (
          this.webUtil.BN(this.tx.gas_used)
            .div(this.tx.gas)
            .times(100)
            .toFixed(2) - 0
        );
      }
      return 0;
    },
  },
  created() {
    this.getExtrinsic();
  },
  methods: {
    getExtrinsic() {
      this.txLoading = true;
      this.axios
        .get(this.domain + "evm/transaction/hash/" + this.txID)
        .then((res) => {
          this.txLoading = false;
          this.tx = res.data.data;
        })
        .catch((e) => {
          console.log(e);
          this.tx = null;
          this.txLoading = false;
        });
    },
  },
  components: {
    loading,
    // StructTable,
    StructAddress,
  },
  watch: {
    txID(to) {
      this.getExtrinsic();
    },
  },
};
</script>

<style scoped>
a {
  vertical-align: middle;
}
</style>
