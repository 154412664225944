<template>
  <p>
    <router-link class="text-blue" :to="(isEVM==1?'/evm/':'/account/') + address">{{
      address
    }}</router-link>
    <img
      class="copy-btn"
      @click="copyAction(address)"
      src="@/static/img/icon/copy_grey.png"
      width="16"
    />
  </p>
</template>
<script>
export default {
  props: ["address","isEVM"],
};
</script>
<style scoped>
a {
  vertical-align: middle;
}
</style>